export const listOfNames: string[] = [
    "bandalls",
    "wattlexp",
    "sweetiele",
    "hyperyaufarer",
    "editussion",
    "experthead",
    "flamesbria",
    "heroanhart",
    "liveltekah",
    "linguss",
    "interestec",
    "fuzzyspuffy",
    "rubblecannon",
    "lovesboost",
    "edgymnerch",
    "tripgunner",
    "hashtag",
    "wardonboy",
    "cerealface",
    "chewchew",
    "bitsentinel",
    "drugstorecowboy",
    "fastdraw",
    "mr.blonde",
    "wildcat",
    "brighthulk",
    "skychaser",
    "spuffyffet",
    "rozalthiric",
    "bookman",
    "Mang0",
    "plup",
    "ibdw",
    "leffen",
    "amsa",
    "m2k",
    "armada",
    "ppmd",
    "hbox",
  ]